import React, { useState } from 'react'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({}))

const sites = {
  '5cc76bdd6c26530b4a01c704': 'Padel Tennis Club Montjoie',
  '5cc76bcd6c26530b4a01c703': 'Royal Brussels Lawn Tennis Club',
  '5cc76bbb6c26530b4a01c702': 'Tennis Club Uccle Churchilll',
}

const FormPreferredSite = ({ value, onChange }) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const Items = Object.keys(sites).map(key => {
    return (
      <FormControlLabel
        value={key}
        key={key}
        control={<Radio size="small" />}
        label={sites[key]}
      />
    )
  })

  const handleChange = event => {
    const newValue = event.target.value
    onChange('preferredSite', { id: newValue, name: sites[newValue] })
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={value ? value.id : null}
        onChange={handleChange}
      >
        {Items}
      </RadioGroup>
    </FormControl>
  )
}

FormPreferredSite.defaultProps = {}

export default FormPreferredSite
