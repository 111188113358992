import axios from 'axios'
import moment from 'moment'
import swal from 'sweetalert2'

import { ContactTemplate, ContactTemplateSection } from 'inno-components'

import { sendMail } from './email'

export const convertFormValuesToObject = values => {
  const formData = {}

  values.forEach(v => {
    formData[v.name] = v.value
  })

  return formData
}

const getHtmlFromArray = values => {
  //CYCLE
  //event.name -> Titre
  //person.availability --> iterate with :
  /*
  Object.entries(person.availability).forEach(entry => {
    const [key, value] = entry;
    console.log(key, value);
  });
  **/
  //person.birthdate
  //person.email
  //person.firstname
  //person.lastname
  //person.mobilePhone
  //person.question

  //STAGES
  //event.name -> Titre
  //person.level
  //preferredSite.name
  const html = [];

  values.forEach(value => {
    html.push(`<h1>${value.event.name}</h1>`);
    html.push(`<b>Nom</b> : <p>${value.person.firstname}</P>`);
    html.push(`<b>Prénom</b> :<p>${value.person.lastname}</P>`);
    html.push(`<b>Date de naissance</b> :<p>${value.person.birthdate}</P>`);
    html.push(`<b>Adresse e-mail</b> :<p>${value.person.email}</P>`);
    html.push(`<b>Numéro de téléphone</b> :<p>${value.person.mobilePhone}</P>`);
    html.push(`<b>Question</b>: </br><pre>${value.person.question}</Pre>`);

    if(((value.person) && (value.person.hasOwnProperty("level")) && (value.person.level!="none")) || ((value.preferredSite)&&(value.preferredSite.hasOwnProperty("name")))) html.push(`<h3>Stages</h3>`);
    if((value.person) && (value.person.hasOwnProperty("level")) && (value.person.level!="none")) html.push(`<b>Niveau d'apprentissage</b> :<p>${value.person.level}</p>`);
    if((value.preferredSite)&&(value.preferredSite.hasOwnProperty("name"))) html.push(`<b>CLUB DE PRÉFÉRENCE</b> :<p>${value.preferredSite.name}</p>`);
    html.push(`</br></hr>`);
  });

  return html
}

export const addOnlineRegistration = registrations => {
  /*
  axios
    .post(
      'https://f521f36d809d4b478816430340078299.us-east-1.aws.found.io:9243/bts-online-registrations/_doc',
      {
        date: new Date(),
        registrations: registrations,
      },
      {
        auth: {
          username: 'elastic',
          password: 'B2stgHVn9JyU5fflksLvUz0X',
        },
        //withCredentials: true
      }
    )
    .catch(e => {
      console.log('error')
    })
  return axios.post(
    'https://app-ea754c1b-942b-4aa0-8f2d-a060371ae1f2.cleverapps.io/api/online-registrations',
    //'http://bmarguerite.fr:9090/api/online-registrations',
    registrations
  )
  **/
 let template ="";


 //console.log(registrations[0].person);
  //console.log(registrations);
  template = getHtmlFromArray(registrations);
  //console.log(template);
  return sendMail(
    "noreply@brusselstennisschool.be",
    "info@brusselstennisschool.be;Cthys@brusselstennisschool.be;adecourriere@hotmail.com;francesco_i_87@hotmail.com",
    "Inscription cycle ou Stage ou demande de renseignements",
    "innobelge.be",
    template.join(""),
    null
  )
}
