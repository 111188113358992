import React, { useState } from 'react'
import Img from 'gatsby-image'
import { Link, Element } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from 'react-helmet'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Club from '../components/Club'
import Form from '../components/Form'
import Button from '../components/Button'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(6),
  },
  image: {
    display: 'block',
    position: 'absolute !important',
    bottom: -150,
    right: 0,
    zIndex: 2,
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  groupTitle: {
    marginTop: theme.spacing(5),
  },
  sectionContainer: {
    position: 'relative',
  },
}))

const CyclePage = ({ cycle }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "tenniswoman1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const baseTranslationKey = 'cycles:' + cycle

  const Groups = t(baseTranslationKey + '.groups.content', {
    returnObjects: true,
  }).map((group, key) => {
    return (
      <Grid item xs={12} sm={4} key={key.toString()}>
        <Typography
          variant="h6"
          color="secondary"
          className={classes.groupTitle}
        >
          <strong>{group.title}</strong>
        </Typography>
        <p className={classes.groupSubtitle}>{group.content}</p>
        <p className={classes.groupCaption}>
          <em>{group.subtitle}</em>
        </p>
        <Typography
          variant="subtitle2"
          color="primary"
          className={classes.groupPrice}
        >
          <strong>{group.footer}</strong>
        </Typography>
      </Grid>
    )
  })

  return (
    <React.Fragment>
      <Helmet
        title={t(baseTranslationKey + '.head.title')}
        meta={[
          {
            name: 'description',
            content: t(baseTranslationKey + '.head.description'),
          },
        ]}
      />

      <Section bg="#fafcff" className={classes.sectionContainer}>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <Typography
              component="h1"
              variant="h2"
              className={classes.title}
              gutterBottom
            >
              {t(baseTranslationKey + '.title')}
            </Typography>
            <Heading
              title={t(baseTranslationKey + '.introduction.title')}
              variant="secondary"
            />
            {t(baseTranslationKey + '.introduction.content', {
              returnObjects: true,
            }).map((text, key) => (
              <p
                key={key.toString()}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></p>
            ))}
            <Grid item container xs={12}>
              <Link to="formulaire" smooth={true} duration={500}>
                <Button className={classes.button}>
                  {t(baseTranslationKey + '.introduction.button')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Img
          fluid={data.backgroundImage.childImageSharp.fluid}
          className={classes.image}
        />
      </Section>
      <Section bg="#E7F1FE">
        <Heading title="Clubs" variant="secondary" />
        <Grid container spacing={2}>
          <Grid item sm={3} xs={12}>
            <Club club={t('clubs:royal', { returnObjects: true })} />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Club club={t('clubs:churchill', { returnObjects: true })} />
          </Grid>
          {/* 
           <Grid item sm={3} xs={12}>
            <Club club={t('clubs:leopold', { returnObjects: true })} />
          </Grid>
          
          */}
          <Grid item sm={3} xs={12}>
            <Club club={t('clubs:montjoie', { returnObjects: true })} />
          </Grid>
          
        </Grid>
      </Section>
      <Section bg="#fafcff">
        <Heading
          title={t(baseTranslationKey + '.groups.title')}
          variant="secondary"
        />
        <Grid container spacing={3}>
          {Groups}
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                color="secondary"
                className={classes.groupTitle}
              >
                <strong>
                  {t(baseTranslationKey + '.groups.competitive.title')}
                </strong>
              </Typography>
              <p className={classes.groupSubtitle}>
                {t(baseTranslationKey + '.groups.competitive.content')}
              </p>
              <p className={classes.groupCaption}>
                <em>
                  {t(baseTranslationKey + '.groups.competitive.subtitle')}
                </em>
              </p>
              <Typography
                variant="subtitle2"
                color="primary"
                className={classes.groupPrice}
              >
                <strong>
                  {t(baseTranslationKey + '.groups.competitive.footer')}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                color="secondary"
                className={classes.groupTitle}
              >
                <strong>
                  {t(baseTranslationKey + '.groups.training.title')}
                </strong>
              </Typography>
              <p className={classes.groupSubtitle}>
                {t(baseTranslationKey + '.groups.training.content')}
              </p>
              <p className={classes.groupCaption}>
                <em>{t(baseTranslationKey + '.groups.training.subtitle')}</em>
              </p>
              <Typography
                variant="subtitle2"
                color="primary"
                className={classes.groupPrice}
              >
                <strong>
                  {t(baseTranslationKey + '.groups.training.footer')}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Element id="formulaire" name="formulaire">
        <Section bg="#E7F1FE">
          <Heading title={t('form:titles.cycle')} />
          <Form
            variant="cycle"
            event={{
              id: t(baseTranslationKey + '.id'),
              name: t(baseTranslationKey + '.title'),
            }}
          />
        </Section>
      </Element>
    </React.Fragment>
  )
}

CyclePage.defaultProps = {}

export default CyclePage
