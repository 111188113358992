import React, { useState } from 'react'
import clsx from 'clsx'
import { useFormik, FormikProvider } from 'formik'
import chroma from 'chroma-js'
import { useStaticQuery, graphql } from 'gatsby'
import swal from 'sweetalert2'
import { KeyboardDatePicker } from '@material-ui/pickers'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { makeStyles } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import Grid from '@material-ui/core/Grid'

import CircularProgress from '@material-ui/core/CircularProgress'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import Button from '../Button'
import FormPreferredSite from './FormPreferredSite'
import { addOnlineRegistration } from '../../config/crm'

import { TextareaAutosize } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    zIndex: 0,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
  },
  waves: {
    position: 'absolute',
    top: -1,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    opacity: 0.7,
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 5,
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  form: {
    display: 'block',
    maxWidth: 900,
    backgroundColor: '#fff',
    boxShadow: '10px 10px 48px 0px rgba(37,53,60,0.2)',
    borderRadius: '20px',
    padding: theme.spacing(4),
    position: 'relative',
    zIndex: 6,
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 600,
    display: 'block',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  input: {
    display: 'block',
    width: '100%',
    fontSize: '1rem',
    padding: theme.spacing(2),
    backgroundColor: '#F8F9FF',
    border: 0,
    //border: "solid 2px " + theme.palette.primary.main,
    boxShadow:
      'inset 0 2px 4px 0 ' +
      chroma(theme.palette.primary.main)
        .alpha(0.1)
        .css(),
    borderRadius: '6px',
    outline: 'none',
  },
  textarea: {
    minHeight: '250px',
    width: '100%',
    resize: 'vertical',
  },
  inputError: {
    color: red[800],
    backgroundColor: red[100],
  },
  labelError: {
    color: red[800],
  },
  error: {
    color: red[800],
    fontWeight: 600,
    display: 'block',
    fontSize: '0.7rem',
    marginTop: theme.spacing(2),
  },
  progress: {
    color: '#fff',
  },
  selectInput: {
    display: 'block',
    width: '100%',
    backgroundColor: '#F8F9FF',
    border: 0,
    //border: "solid 2px " + theme.palette.primary.main,
    boxShadow:
      'inset 0 2px 4px 0 ' +
      chroma(theme.palette.primary.main)
        .alpha(0.1)
        .css(),
    borderRadius: '6px',
    outline: 'none',
  },
  button: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
}))

const InputDateComponent = fieldProps => {
  const classes = useStyles()
  console.log('fieldProps', fieldProps)
  const { InputProps, ...inputProps } = fieldProps
  return (
    <React.Fragment>
      <InputBase
        fullWidth
        {...inputProps}
        {...InputProps}
        classes={{
          input: clsx(classes.input, fieldProps.error && classes.inputError),
        }}
      />
      {fieldProps.error && (
        <span className={classes.error}>{fieldProps.helperText}</span>
      )}
    </React.Fragment>
  )
}

const Form = ({ variant, stages, organization, event }) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const phoneRegExp = /^((\+)\d{2}\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/
  const FormSchema =
    variant === 'stage'
      ? Yup.object().shape({
          firstname: Yup.string().required('Ce champ est requis '),
          lastname: Yup.string().required('Ce champ est requis '),
          email: Yup.string()
            .email('Adresse email invalide')
            .required('Ce champ est requis '),
          birthdate: Yup.date()
            .nullable()
            .required('Ce champ est requis '),
          mobilePhone: Yup.string().matches(phoneRegExp, 'Format non valide. Format requis +32 489 64 14 55'),
          events: Yup.array().required('Ce champ est requis '),
        })
      : Yup.object().shape({
          firstname: Yup.string().required('Ce champ est requis '),
          lastname: Yup.string().required('Ce champ est requis '),
          email: Yup.string()
            .email('Adresse email invalide')
            .required('Ce champ est requis '),
          birthdate: Yup.date()
            .nullable()
            .required('Ce champ est requis '),
          mobilePhone: Yup.string().matches(phoneRegExp, 'Format non valide. Format requis +32 489 64 14 55'),
        })

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      mobilePhone: '',
      birthdate: null,
      level: 'none',
      availability: [],
      events: [],
      preferredSite: null,
      question:"",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: FormSchema,
    onSubmit: (response, { resetForm, setSubmitting }) => {
      setIsLoading(true)
      setSubmitting(true)

      const formatedAvailability = {}
      response.availability.forEach(dayAvailable => {
        formatedAvailability[dayAvailable] = true
      })
      const registrations =
        variant === 'stage'
          ? response.events.map(eventSelected => ({
              organization,
              event: {
                name: eventSelected,
              },
              person: {
                firstname: response.firstname.trim(),
                lastname: response.lastname.trim(),
                email: response.email.trim(),
                birthdate: response.birthdate.toDate(),
                mobilePhone: response.mobilePhone,
                availability: formatedAvailability,
                level: response.level,
                question: response.question.trim(),
              },
              preferredSite: response.preferredSite,
              parent: {
                firstname: 'dfsdf',
                lastname: 'sdfsdfs',
              },
            }))
          : [
              {
                organization,
                event,
                person: {
                  firstname: response.firstname.trim(),
                  lastname: response.lastname.trim(),
                  email: response.email.trim(),
                  birthdate: response.birthdate.toDate(),
                  mobilePhone: response.mobilePhone,
                  availability: formatedAvailability,
                  level: response.level,
                  question: response.question.trim(),
                },
                preferredSite: response.preferredSite,
                parent: {
                  firstname: 'dfsdf',
                  lastname: 'sdfsdfs',
                },
              },
            ]

      return addOnlineRegistration(registrations)
        .then(() => {
          setIsLoading(false)
          setSubmitting(false)
          resetForm()
          return swal.fire({
            icon: 'success',
            title: t('form:alert.success.title'),
            text: t('form:alert.success.text'),
          })
        })
        .catch(error => {
          return swal.fire({
            icon: 'error',
            title: t('form:alert.error.title'),
            text: t('form:alert.error.text'),
          })
        })
    },
  })

  let LastField
  let messageField

  messageField = (
    <TextField
        InputProps={{
            classes: {
            input: clsx(
                classes.input,
                formik.errors.question &&
                formik.touched.question &&
                classes.inputError
            ),
            },
            disableUnderline: true,
        }}
        style={{textAlign:'left'}}
        rows={10}
        multiline
        margin="none"
        fullWidth
        id="question"
        name="question"
        placeholder={t('form:fields.question')}
        type="text"
        onChange={formik.handleChange}
        value={formik.values.question}
    />
  )

  if (variant === 'stage') {
    const stagesValues = {}

    stages.forEach(stage => {
      stagesValues[stage.id] = stage.label
    })

    LastField = (
      <React.Fragment>
        <Select
          id="events"
          value={formik.values.events}
          multiple
          displayEmpty
          onChange={e => {
            formik.setFieldValue('events', e.target.value)
          }}
          renderValue={selected => {
            if (selected.length === 0) {
              return <em>{t('form:fields.stages')}</em>
            }

            return selected
              .map(selectValue => selectValue)
              .join(', ')
          }}
          input={
            <InputBase
              fullWidth
              classes={{
                input: clsx(
                  classes.input,
                  formik.errors.events &&
                    formik.touched.events &&
                    classes.inputError
                ),
              }}
            />
          }
        >
          <MenuItem disabled value="">
            <em>{t('form:fields.stages')}</em>
          </MenuItem>
          {stages.map(stage => (
            <MenuItem key={stage.id} value={stage.label}>
              {stage.label}
            </MenuItem>
          ))}
        </Select>
        {formik.errors.events && (
          <span className={classes.error}>{formik.errors.events}</span>
        )}
      </React.Fragment>
    )
  }

  if (variant === 'cycle') {
    /*  
    const availabilityValues = {}
    t('form:fields.availability.options', {
      returnObjects: true,
    }).forEach(availability => {
      availabilityValues[availability.value] = availability.label
    })
    LastField = (
      <Select
        id="availability"
        value={formik.values.availability}
        multiple
        displayEmpty
        onChange={e => {
          formik.setFieldValue('availability', e.target.value)
        }}
        renderValue={selected => {
          if (selected.length === 0) {
            return <em>{t('form:fields.availability.label')}</em>
          }

          return selected
            .map(selectValue => availabilityValues[selectValue])
            .join(', ')
        }}
        input={
          <InputBase
            fullWidth
            classes={{
              input: clsx(
                classes.input,
                formik.errors.availability &&
                  formik.touched.availability &&
                  classes.inputError
              ),
            }}
          />
        }
      >
        <MenuItem disabled value="">
          <em>{t('form:fields.availability.label')}</em>
        </MenuItem>
        {t('form:fields.availability.options', {
          returnObjects: true,
        }).map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      
    )
    **/
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        <div className={classes.container}>
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            <Grid container>
              <Grid container item xs={12} spacing={3}>
                <Grid item md={12} xs={12}>
                  <div className={classes.label}>
                    {t('form:labels.contact')}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        input: clsx(
                          classes.input,
                          formik.errors.firstname &&
                            formik.touched.firstname &&
                            classes.inputError
                        ),
                      },
                      disableUnderline: true,
                    }}
                    size="small"
                    margin="none"
                    fullWidth
                    id="firstname"
                    name="firstname"
                    placeholder={t('form:fields.firstname')}
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.firstname}
                  />
                  {formik.errors.firstname && formik.touched.firstname && (
                    <span className={classes.error}>
                      {formik.errors.firstname}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        input: clsx(
                          classes.input,
                          formik.errors.lastname &&
                            formik.touched.lastname &&
                            classes.inputError
                        ),
                      },
                      disableUnderline: true,
                    }}
                    size="small"
                    margin="none"
                    fullWidth
                    id="lastname"
                    name="lastname"
                    type="text"
                    placeholder={t('form:fields.lastname')}
                    onChange={formik.handleChange}
                    value={formik.values.lastname}
                  />
                  {formik.errors.lastname && formik.touched.lastname && (
                    <span className={classes.error}>
                      {formik.errors.lastname}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        input: clsx(
                          classes.input,
                          formik.errors.mobilePhone &&
                            formik.touched.mobilePhone &&
                            classes.inputError
                        ),
                      },
                      disableUnderline: true,
                    }}
                    size="small"
                    margin="none"
                    fullWidth
                    id="mobilePhone"
                    name="mobilePhone"
                    type="text"
                    placeholder={t('form:fields.mobilePhone')}
                    onChange={formik.handleChange}
                    value={formik.values.mobilePhone}
                  />
                  {formik.errors.mobilePhone && formik.touched.mobilePhone && (
                    <span className={classes.error}>
                      {formik.errors.mobilePhone}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        input: clsx(
                          classes.input,
                          formik.errors.email &&
                            formik.touched.email &&
                            classes.inputError
                        ),
                      },
                      disableUnderline: true,
                    }}
                    size="small"
                    margin="none"
                    fullWidth
                    id="email"
                    name="email"
                    type="text"
                    placeholder={t('form:fields.email')}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <span className={classes.error}>{formik.errors.email}</span>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item md={12} xs={12}>
                  <div className={classes.label}>
                    {t('form:labels.informations')}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    clearable
                    TextFieldComponent={InputDateComponent}
                    disableFuture
                    error={formik.errors.birthdate && formik.touched.birthdate}
                    helperText={formik.errors.birthdate}
                    placeholder={t('form:fields.birthdate')}
                    value={formik.values.birthdate}
                    onChange={date => {
                      formik.setFieldValue('birthdate', date, false)
                    }}
                    format="DD/MM/YYYY"
                    openTo="year"
                  />
                </Grid>
                    {variant === 'stage' && (
                    <Grid item xs={12} sm={6}>
                    <Select
                        id="level"
                        value={formik.values.level}
                        onChange={e => {
                        formik.setFieldValue('level', e.target.value)
                        }}
                        input={
                        <InputBase
                            fullWidth
                            classes={{
                            input: clsx(
                                classes.input,
                                formik.errors.nom &&
                                formik.touched.nom &&
                                classes.inputError
                            ),
                            }}
                        />
                        }
                    >
                        <MenuItem value="none" disabled>
                        <em>{t('form:fields.level.label')}</em>
                        </MenuItem>
                        {t('form:fields.level.options', {
                        returnObjects: true,
                        }).map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </Select>
                    </Grid>
                )}
                {variant === 'stage' && (
                  <Grid container item xs={12}>
                    <Grid item md={12} xs={12}>
                      <div className={classes.label}>
                        {t('form:labels.club')}
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormPreferredSite
                        value={formik.values.preferredSite}
                        onChange={formik.setFieldValue}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container item xs={12}>
                  {variant === 'stage' && (
                    <Grid item md={12} xs={12}>
                      <div className={classes.label}>
                        {t('form:labels.stages')}
                      </div>
                    </Grid>
                  )}
                  <Grid item md={12} xs={12}>
                    {LastField}
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item md={12} xs={12}>
                      <div className={classes.label}>
                        {t('form:labels.question')}
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        {messageField}
                    </Grid>
                </Grid>
              </Grid>

              <Grid item container justify="center">
                <span type="submit" color="primary">
                  <Button type="submit" className={classes.button}>
                    {isLoading ? (
                      <CircularProgress
                        size={24}
                        className={classes.progress}
                        color="secondary"
                      />
                    ) : (
                      t('form:buttons.' + variant)
                    )}
                  </Button>
                </span>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  )
}

Form.defaultProps = {
  variant: 'cycle',
  organization: {
    id: '5c51e2222fd0a70d780bc12c',
    name: 'Brussels Tennis School',
  },
}

export default Form
